import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/CompanyDetails.css';

const CompanyDetails = () => {
    const { id } = useParams();
    const [company, setCompany] = useState(null);
    const [ansprechpartner, setAnsprechpartner] = useState(null);
    const [zutrittszeiten, setZutrittszeiten] = useState(null);
    const [error, setError] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL || 'http://avisierung.de';

    // Pobieranie szczegółów firmy
    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await fetch(`${API_URL}/companies-details/${id}`);
                if (!response.ok) {
                    throw new Error('Fehler beim Laden der Firmendetails.');
                }
                const responseData = await response.json();
                const { company, ansprechpartner, zutrittszeiten } = responseData.data;

                setCompany(company);
                setAnsprechpartner(ansprechpartner);
                setZutrittszeiten(zutrittszeiten);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchCompanyDetails();
    }, [id]);


    useEffect(() => {
        if (!company || !company.id) return; // Überprüfen, ob company und die ID vorhanden sind
        const fetchAnsprechpartner = async () => {
            try {
                const response = await fetch(`${API_URL}/ansprechpartner/${company.id}`);
                if (!response.ok) {
                    throw new Error('Fehler beim Laden der Ansprechpartner.');
                }
                const data = await response.json();
                setAnsprechpartner(data); // Daten im State speichern
            } catch (error) {
                console.error('Fehler beim Abrufen der Ansprechpartner:', error);
            }
        };
        fetchAnsprechpartner();
    }, [company]); // Nur ausführen, wenn company sich ändert

    useEffect(() => {
        if (!company || !company.id) return; // Überprüfen, ob company und die ID vorhanden sind
        const fetchZutrittszeiten = async () => {
            try {
                const response = await fetch(`${API_URL}/zutrittszeiten/${company.id}`);
                if (!response.ok) {
                    throw new Error('Fehler beim Laden der Zutrittszeiten.');
                }
                const data = await response.json();
                setZutrittszeiten(data); // Daten im State speichern
            } catch (error) {
                console.error('Fehler beim Abrufen der Zutrittszeiten:', error);
            }
        };
        fetchZutrittszeiten();
    }, [company]); // Nur ausführen, wenn company sich ändert



    // Funkcja zmieniająca status Genehmigt w bazie danych i w stanie lokalnym
    const toggleGenehmigtStatus = async () => {
        if (!company) return;
        try {
            const newStatus = company.fa_g ? 0 : 1; // Zmiana statusu
            const response = await fetch(`${API_URL}/update-status/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fa_g: newStatus }),
            });

            if (!response.ok) {
                throw new Error('Nie udało się zaktualizować statusu.');
            }

            const updatedCompany = await response.json();
            setCompany({ ...company, fa_g: updatedCompany.fa_g }); // Aktualizacja lokalnego stanu
        } catch (error) {
            console.error('Błąd podczas zmiany statusu:', error);
            alert('Nie udało się zmienić statusu.');
        }
    };

    if (error) return <div className="error">{error}</div>;
    if (!company) return <div className="loading">Ładowanie szczegółów firmy...</div>;

    return (
        <div className="company-details">
            <div className="header">
                <h1>{company.name}</h1>
            </div>
            <div className="menu">
                <ul>
                    <li>Übersicht</li>
                    <li>Bescheinigungen</li>
                    <li>Mitarbeiter</li>
                    <li>Subunternehmen</li>
                    <li>Vergabeeinheiten</li>
                    <li>Logs</li>
                </ul>
            </div>

            <div className="status-row">
                <div className={`status-box ${company.fa_aktiv ? 'status-active' : 'status-inactive'}`}>
                    <span>Status</span>
                    <div>{company.fa_aktiv ? '✔️ Aktywna' : '❌ Nieaktywna'}</div>
                </div>
                <div
                    className={`status-box ${company.fa_g ? 'status-active' : 'status-inactive'}`}
                    onClick={toggleGenehmigtStatus}
                    style={{cursor: 'pointer'}}
                >
                    <span>Genehmigt</span>
                    <div>{company.fa_g ? '✔️ Genehmigt' : '❌ Nicht genehmigt'}</div>
                </div>
                <div className="status-box status-active">
                    <span>Status der Prüfung</span>
                    <div>✔️</div>
                </div>
            </div>

            <div className="info-section">

                    <div className="column column-stammdaten">
                        <h2>Stammdaten</h2>
                        <p><strong>Straße:</strong> {company.strasse}</p>
                        <p><strong>PLZ:</strong> {company.plz}</p>
                        <p><strong>Stadt:</strong> {company.ort}</p>
                        <p><strong>Land:</strong> {company.land}</p>
                        <p><strong>Telefon:</strong> {company.telefon}</p>
                        <p><strong>E-Mail:</strong> {company.email}</p>
                    </div>

                    <div className="column column-ansprechpartner">
                        <h2>Ansprechpartner</h2>
                        {ansprechpartner ? (
                            ansprechpartner.map((ansprech, index) => (
                                <div key={index}>
                                    <p>
                                        <strong>Name:</strong> {ansprech.name} {ansprech.vorname}
                                    </p>
                                    <p><strong>Telefon:</strong> {ansprech.telefon}</p>
                                    <p><strong>E-Mail:</strong> {ansprech.email}</p>
                                </div>
                            ))
                        ) : (
                            <p>Keine Ansprechpartner verfügbar.</p>
                        )}
                    </div>

                    <div className="column column-versicherung">
                        <h2>Versicherung</h2>
                    </div>
                
            </div>


            <div className="info-section">

                <div className="info-section-2">
                    <h2>Zutrittszeiten</h2>
                    {zutrittszeiten ? (
                        <table>
                            <thead>
                            <tr>
                                <th>Wochentag</th>
                                <th>Von</th>
                                <th>Bis</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Montag</td>
                                <td>{zutrittszeiten.zt_mo_start || 'Keine Zeit'}</td>
                                <td>{zutrittszeiten.zt_mo_ende || 'Keine Zeit'}</td>
                            </tr>
                            <tr>
                                <td>Dienstag</td>
                                <td>{zutrittszeiten.zt_di_start || 'Keine Zeit'}</td>
                                <td>{zutrittszeiten.zt_di_ende || 'Keine Zeit'}</td>
                            </tr>
                            <tr>
                                <td>Mittwoch</td>
                                <td>{zutrittszeiten.zt_mi_start || 'Keine Zeit'}</td>
                                <td>{zutrittszeiten.zt_mi_ende || 'Keine Zeit'}</td>
                            </tr>
                            <tr>
                                <td>Donnerstag</td>
                                <td>{zutrittszeiten.zt_do_start || 'Keine Zeit'}</td>
                                <td>{zutrittszeiten.zt_do_ende || 'Keine Zeit'}</td>
                            </tr>
                            <tr>
                                <td>Freitag</td>
                                <td>{zutrittszeiten.zt_fr_start || 'Keine Zeit'}</td>
                                <td>{zutrittszeiten.zt_fr_ende || 'Keine Zeit'}</td>
                            </tr>
                            <tr>
                                <td>Samstag</td>
                                <td>{zutrittszeiten.zt_sa_start || 'Keine Zeit'}</td>
                                <td>{zutrittszeiten.zt_sa_ende || 'Keine Zeit'}</td>
                            </tr>
                            <tr>
                                <td>Sonntag</td>
                                <td>{zutrittszeiten.zt_so_start || 'Keine Zeit'}</td>
                                <td>{zutrittszeiten.zt_so_ende || 'Keine Zeit'}</td>
                            </tr>
                            </tbody>
                        </table>
                    ) : (
                        <p>Keine Zutrittszeiten verfügbar.</p>
                    )}
                </div>

            </div>


        </div>
    );
};

export default CompanyDetails;
