import React, { useContext, useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { UserContext } from "../context/UserContext"; // Upewnij się, że UserContext jest zaimportowany
import "../styles/HomePage.css"; // Import stylów

const HomePage = () => {
    const { user } = useContext(UserContext); // Pobierz dane użytkownika z kontekstu
    const [baustellen, setBaustellen] = useState([]);
    const { setSelectedBaustelle } = useOutletContext(); // Funkcja do ustawienia wybranej budowy

    // Pobieranie budów w zależności od roli użytkownika
    useEffect(() => {
        if (!user) {
            console.error("Brak danych użytkownika:", user);
            return;
        }

        const fetchBaustellen = async () => {
            try {
                const url =
                    user.firma_id === 0
                        ? `${process.env.REACT_APP_API_URL}/baustellen`
                        : `${process.env.REACT_APP_API_URL}/baustellen/firma?firma_id=${user.firma_id}`;

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Błąd podczas pobierania budów");
                }

                const data = await response.json();
                if (!data.length) {
                    console.warn("Nie znaleziono budów dla podanego firma_id:", user.firma_id);
                }
                setBaustellen(data);
            } catch (error) {
                console.error("Błąd podczas pobierania budów:", error);
            }
        };

        fetchBaustellen();
    }, [user?.firma_id]);

    // Obsługa wyboru budowy
    const handleSelectBaustelle = (baustelle) => {
        setSelectedBaustelle(baustelle);
    };

    return (
        <div className="homepage-container">
            <h1 className="title">Budowy</h1>
            <div className="data-card">
                <div className="data-section">
                    <h2>Dane</h2>
                    <div className="data-item">
                        <span>{baustellen.length} Budowy</span>
                        <span className="data-status">Wszystkie zweryfikowane</span>
                    </div>
                    <div className="data-item">
                        <span>109 Pracowników</span>
                        <span className="data-highlight">4 do weryfikacji</span>
                    </div>
                </div>
                <div className="circle-chart">
                    <p>0 / 109</p>
                </div>
            </div>
            <div className="tile-container">
                {baustellen.length === 0 ? (
                    <p>Brak dostępnych budów.</p>
                ) : (
                    baustellen.map((baustelle) => (
                        <Link
                            to={`/start/${baustelle.id_bv}`}
                            key={baustelle.id_bv}
                            className="tile-link"
                            onClick={() => handleSelectBaustelle(baustelle)}
                        >
                            <div className="tile">
                                <h3>{baustelle.bv_name}</h3>
                                <p>{baustelle.bv_strasse}</p>
                                <p>
                                    {baustelle.bv_plz} {baustelle.bv_stadt}
                                </p>
                                <p>{baustelle.bv_land}</p>
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </div>
    );
};

// Eksport na najwyższym poziomie pliku
export default HomePage;
