import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Form, FormControl, Button, Dropdown } from 'react-bootstrap';
import './MenuFirma.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const MenuFirma = ({ user, onLogout }) => {
    // Hooki muszą być na początku komponentu, niezależnie od warunków
    const [showRoleMenu, setShowRoleMenu] = useState(false);
    const [currentRole, setCurrentRole] = useState(user?.role || 'Brak roli'); // Obsługa braku user

    // Efekt ustawiający aktualną rolę
    useEffect(() => {
        if (user?.role) {
            setCurrentRole(user.role);
        } else {
            console.warn("Brak roli w danych użytkownika:", user);
        }
    }, [user?.role]);

    // Zabezpieczenie przed brakiem użytkownika
    if (!user) {
        console.error("Dane użytkownika są niekompletne lub niezdefiniowane.");
        return <div className="error">Błąd: Brak danych użytkownika.</div>;
    }

    return (
        <Navbar bg="light" expand="lg" fixed="top" className="sticky-menu">
            <div className="container-fluid">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto d-flex align-items-center">
                        <Nav.Link href="#overview" className="menu-item">
                            <i className="bi bi-tools"></i> Übersicht
                        </Nav.Link>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" className="menu-item">
                                <i className="bi bi-building"></i> Unternehmen
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#unternehmen-1">Option 1</Dropdown.Item>
                                <Dropdown.Item href="#unternehmen-2">Option 2</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Nav.Link href="#mitarbeiter" className="menu-item">
                            <i className="bi bi-people"></i> Mitarbeiter
                        </Nav.Link>
                        <Nav.Link href="#anwesenheitsliste" className="menu-item">
                            <i className="bi bi-clipboard-data"></i> Anwesenheitsliste
                        </Nav.Link>
                        <Nav.Link href="#reports" className="menu-item">
                            <i className="bi bi-download"></i> Reports
                        </Nav.Link>
                    </Nav>
                    <Form className="d-flex">
                        <FormControl
                            type="search"
                            placeholder="Suche"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button variant="primary">
                            <i className="bi bi-search"></i>
                        </Button>
                    </Form>
                    <Dropdown align="end">
                        <Dropdown.Toggle variant="link" className="user-info">
                            {user.firstName} {user.lastName} <span className="text-muted">({currentRole})</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item disabled>Profil</Dropdown.Item>
                            <Dropdown.Item onClick={onLogout}>Wyloguj</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

export default MenuFirma;
