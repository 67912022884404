import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import '../styles/Start.css';

const Start = () => {
    const { id } = useParams(); // Pobieranie ID budowy z URL
    const [baustelle, setBaustelle] = useState(null); // Szczegóły budowy
    const [workingHours, setWorkingHours] = useState(null); // Godziny otwarcia
    const [contactPerson, setContactPerson] = useState(null); // Ansprechpartner
    const [coordinates, setCoordinates] = useState(null); // Współrzędne budowy
    const [isAdmin, setIsAdmin] = useState(true); // Tymczasowe ustawienie Admina
    const [ausweisImage, setAusweisImage] = useState(null); // Przechowywanie zdjęcia Ausweisu
    const [preview, setPreview] = useState(null); // Podgląd zdjęcia
    const [editMode, setEditMode] = useState(false); // Tryb edycji

    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const responseBaustelle = await fetch(`${process.env.REACT_APP_API_URL}/baustellen/${id}`);
                if (!responseBaustelle.ok) {
                    throw new Error("Nie udało się pobrać szczegółów budowy.");
                }
                const dataBaustelle = await responseBaustelle.json();
                setBaustelle(dataBaustelle);

                const responseHours = await fetch(`${process.env.REACT_APP_API_URL}/zutrittszeiten/${id}`);
                if (responseHours.ok) {
                    const dataHours = await responseHours.json();
                    setWorkingHours(dataHours);
                }

                const responseContact = await fetch(`${process.env.REACT_APP_API_URL}/ansprechpartner/${id}`);
                if (responseContact.ok) {
                    const dataContact = await responseContact.json();
                    setContactPerson(dataContact);
                }

                const address = `${dataBaustelle.bv_strasse}, ${dataBaustelle.bv_plz} ${dataBaustelle.bv_stadt}, ${dataBaustelle.bv_land}`;
                const geocodeResponse = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`
                );
                const geocodeData = await geocodeResponse.json();
                if (geocodeData.results.length > 0) {
                    const location = geocodeData.results[0].geometry.location;
                    setCoordinates(location);
                }
            } catch (error) {
                console.error("Błąd podczas pobierania danych:", error);
            }
        };

        fetchDetails();
    }, [id, GOOGLE_MAPS_API_KEY]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setAusweisImage(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async () => {
        if (!ausweisImage) {
            alert("Wybierz zdjęcie przed przesłaniem.");
            return;
        }

        const formData = new FormData();
        formData.append("image", ausweisImage);
        formData.append("id", id);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-ausweis`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Nie udało się przesłać zdjęcia.");
            }

            const result = await response.json();
            alert(`Zdjęcie zostało przesłane pomyślnie! Ścieżka pliku: ${result.filePath}`);
        } catch (error) {
            console.error("Błąd podczas przesyłania zdjęcia:", error);
            alert("Wystąpił błąd podczas przesyłania zdjęcia.");
        }
    };

    const toggleEditMode = () => setEditMode((prev) => !prev);

    if (!baustelle || !workingHours) {
        return <div className="start-page">Ładowanie danych budowy...</div>;
    }

    return (
        <div className="start-page">
            <div className="title">Strona główna budowy</div>

            <div className="main-container">
                <div className="left-section">
                    <div className="data-card">
                        <h3>
                            <span className="icon">🏗️</span> Nazwa budowy
                            {isAdmin && (
                                <button className="edit-button" onClick={toggleEditMode}>
                                    Edytuj
                                </button>
                            )}
                        </h3>
                        {!editMode ? (
                            <p className="data-highlight">{baustelle.bv_name}</p>
                        ) : (
                            <input
                                type="text"
                                defaultValue={baustelle.bv_name}
                                className="edit-input"
                            />
                        )}
                    </div>

                    <div className="data-card">
                        <h3>
                            <span className="icon">📍</span> Adres
                            {isAdmin && (
                                <button className="edit-button" onClick={toggleEditMode}>
                                    Edytuj
                                </button>
                            )}
                        </h3>
                        {!editMode ? (
                            <>
                                <p>{baustelle.bv_strasse}</p>
                                <p>{baustelle.bv_plz} {baustelle.bv_stadt}</p>
                                <p>{baustelle.bv_land}</p>
                            </>
                        ) : (
                            <div>
                                <input
                                    type="text"
                                    defaultValue={baustelle.bv_strasse}
                                    className="edit-input"
                                />
                                <input
                                    type="text"
                                    defaultValue={baustelle.bv_plz}
                                    className="edit-input"
                                />
                                <input
                                    type="text"
                                    defaultValue={baustelle.bv_stadt}
                                    className="edit-input"
                                />
                            </div>
                        )}
                    </div>

                    <div className="info-card">
                        <h3>
                            <span className="icon">👤</span> Ansprechpartner
                            {isAdmin && (
                                <button className="edit-button" onClick={toggleEditMode}>
                                    Edytuj
                                </button>
                            )}
                        </h3>
                        {contactPerson ? (
                            !editMode ? (
                                <div className="ansprechpartner-details">
                                    <p><strong>{contactPerson.bv_ansprech_vorname} {contactPerson.bv_ansprech_name}</strong></p>
                                    <p>{contactPerson.bv_ansprech_tel}</p>
                                    <p>{contactPerson.bv_ansprech_email}</p>
                                </div>
                            ) : (
                                <div>
                                    <input
                                        type="text"
                                        defaultValue={contactPerson.bv_ansprech_vorname}
                                        className="edit-input"
                                    />
                                    <input
                                        type="text"
                                        defaultValue={contactPerson.bv_ansprech_name}
                                        className="edit-input"
                                    />
                                </div>
                            )
                        ) : (
                            <p>Brak danych o osobie kontaktowej.</p>
                        )}
                    </div>

                    <div className="data-card">
                        <h3>Godziny otwarcia</h3>
                        <ul>
                            <li>Poniedziałek: {workingHours.zt_mo_start} - {workingHours.zt_mo_ende}</li>
                            <li>Wtorek: {workingHours.zt_di_start} - {workingHours.zt_di_ende}</li>
                            <li>Środa: {workingHours.zt_mi_start} - {workingHours.zt_mi_ende}</li>
                            <li>Czwartek: {workingHours.zt_do_start} - {workingHours.zt_do_ende}</li>
                            <li>Piątek: {workingHours.zt_fr_start} - {workingHours.zt_fr_ende}</li>
                            <li>Sobota: {workingHours.zt_sa_start} - {workingHours.zt_sa_ende}</li>
                            <li>Niedziela: {workingHours.zt_so_start} - {workingHours.zt_so_ende}</li>
                        </ul>
                    </div>
                </div>

                <div className="right-section">
                    <div className="google-maps">
                        <h3><span className="icon">🗺️</span> Mapa</h3>
                        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                            <GoogleMap
                                mapContainerStyle={{ height: "100%", width: "100%" }}
                                center={coordinates || { lat: 52.52, lng: 13.405 }}
                                zoom={15}
                            >
                                {coordinates && <Marker position={coordinates} />}
                            </GoogleMap>
                        </LoadScript>
                    </div>

                    {isAdmin && (
                        <div className="ausweis-upload">
                            <h3><span className="icon">📷</span> Dodaj zdjęcie Ausweisu</h3>

                            <label htmlFor="file-upload" className="file-label">
                                Datei auswählen
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />

                            {ausweisImage ? (
                                <div className="file-info">{ausweisImage.name}</div>
                            ) : (
                                <div className="file-info">Keine Datei ausgewählt</div>
                            )}

                            <button onClick={handleSubmit} className="submit-button">
                                Prześlij zdjęcie
                            </button>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default Start;
