import React, { useState, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import router from "./routes/AppRouter";
import Login from "./pages/Login";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log("Sprawdzanie stanu użytkownika...");
    const storedUser = localStorage.getItem("user");
    console.log("Dane z localStorage:", storedUser);
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        console.log("Zalogowany użytkownik:", parsedUser);

        if (!parsedUser.firma_id) {
          console.error("Brak firma_id w zapisanych danych użytkownika.");
        }

        setUser(parsedUser);
      } catch (error) {
        console.error("Błąd parsowania użytkownika:", error);
        localStorage.removeItem("user");
      }
    }
  }, []);

  const handleLogin = (userData) => {
    console.log("Logowanie użytkownika:", userData);
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const handleLogout = () => {
    console.log("Wylogowanie użytkownika.");
    setUser(null);
    localStorage.removeItem("user");
  };

  if (!user) {
    console.log("Brak użytkownika, wyświetlanie strony logowania.");
    return (
        <UserProvider value={{ user, handleLogin, handleLogout }}>
          <Login onLogin={handleLogin} />
        </UserProvider>
    );
  }

  return (
      <UserProvider value={{ user, handleLogin, handleLogout }}>
        <RouterProvider router={router} />
      </UserProvider>
  );
}

export default App;
